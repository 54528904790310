import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingIndicatorComponent {
    @Input() public loading = false;
    @Input() public loadingText: string;

    constructor() {
        // Nothing to do here
    }
}
